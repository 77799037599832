<template>
<div>
  <b-row v-if="old_app_loading">
    <b-col md="12" lg="12" sm="12">
      <b-skeleton-table
        :rows="2"
        :columns="2"
        :table-props="{ bordered: true, striped: true }"
      ></b-skeleton-table>
    </b-col>
  </b-row>
  <b-row v-else>
    <b-col md="12" lg="12" sm="12" class="mb-4" v-if="application.type === 2 && isExpiredTime">
      <div>
        <b-alert show dismissible>
          {{ $t('dealer.expire_status')}} <b>&rArr;</b>
        </b-alert>
      </div>
    </b-col>
    <slot v-else>
    <b-col md="12" lg="12" sm="12" class="mb-4">
      <div class="text-center mt-2">
        <b-form-radio-group
          id="payment_type"
          v-model="type"
          :options="paidTypeList"
        ></b-form-radio-group>
      </div>
    </b-col>
     <!-- <pre>sdfsd{{type}}{{paymentList}}</pre> -->
    <component :is="comp" :payments="payments"></component>
    </slot>
  </b-row>
</div>
</template>
<script>
import RestApi, { licenseRegistrationServiceBaseUrl } from '@/config/api_config'
import OnlinePayment from '../../components/PaymentOnline.vue'
import OfflinePayment from '../../components/PaymentOffline.vue'
import moment from 'moment'

export default {
  props: ['application'],
  name: 'Payment',
  components: {
    OnlinePayment,
    OfflinePayment
  },
  data () {
    return {
      old_app_loading: false,
      comp: 'OnlinePayment',
      type: 1,
      payments: [],
      isRenewTime: false,
      isLateTime: false,
      isExpiredTime: false
    }
  },
    watch: {
    type: function (n, o) {
      if (n !== o) {
        if (n === 1) {
          this.comp = 'OnlinePayment'
        } else {
          this.comp = 'OfflinePayment'
        }
      }
    }
  },
  computed: {
    paidTypeList () {
      return [
        {
          text: this.$i18n.locale === 'bn' ? 'অনলাইন' : 'Online',
          value: 1
        },
        {
          text: this.$i18n.locale === 'bn' ? 'অফলাইন' : 'Offline',
          value: 2
        }
      ]
    },
    paymentList () {
      const paymentTypeList = this.$store.state.ExternalUserService.dealerPanel.commonObj.paymentTypeList
      if (this.application.type === 1) {
        return paymentTypeList.filter(item => (item.value !== 4 && item.value !== 5 && item.step_id === parseInt(this.application.status)))
      } else {
        if (this.isLateTime) {
          return paymentTypeList.filter(item => (item.value === 4 || item.value === 5))
        } else {
          if (this.isRenewTime) {
            return paymentTypeList.filter(item => (item.value === 4))
          } else {
            return ''
          }
        }
      }
    }
  },
  created () {
    this.getPaymentTypeWise()
    this.getPaymentList()
    this.getOldAppData()
    if (this.application.id) {
    }
  },
  methods: {
    getPaymentTypeWise () {
      const currentDate = moment().format('YYYY-MM-DD')
      const expiredDate = this.application.expire_date
      const expireNextDate = moment(expiredDate).add(1, 'day').format('YYYY-MM-DD')
      const expirePrevMonthDate = moment(expireNextDate).subtract(1, 'month').format('YYYY-MM-DD')
      const expireNextYearDate = moment(expiredDate).add(365, 'day').format('YYYY-MM-DD')
      const isBetween = moment(currentDate).isBetween(expireNextDate, expireNextYearDate, undefined, '[]')
      if (moment(currentDate).isAfter(expireNextYearDate)) {
        this.isExpiredTime = true
      } else {
        if (isBetween) {
          this.isLateTime = true
        } else {
          const isBetween2 = moment(currentDate).isBetween(expirePrevMonthDate, expiredDate, undefined, '[]')
          if (isBetween2) {
            this.isRenewTime = true
          }
        }
      }
    },
    getPaymentList () {
      const mytemp = this.paymentList
      const datas = []
       mytemp.forEach(item => {
        const fee = item
        const tmp = Object.assign(fee,
          {
            tcb_application_id: this.application.id,
            type: this.application.details?.company_union_id > 1 ? 1 : 2,
            notice_id: this.application.notice_id,
            [this.application.details?.company_union_id > 1 ? 'union_id' : 'ward_id']: this.application.details?.company_union_id > 1 ? this.application.details?.company_union_id : this.application.details?.company_ward_id,
            paid_type: this.type,
            payment_type: fee.value,
            vat: 0,
            tax: 0,
            amount_vat: 0,
            amount_tax: 0,
            pay_date: ''
          })
        datas.push(tmp)
      })
      this.payments = datas
    },
    async getOldAppData () {
      this.old_app_loading = true
      const result = await RestApi.getData(licenseRegistrationServiceBaseUrl, `/manage-information/renew/old-application/${this.application.old_tcb_application_id}`)
      this.old_app_loading = false
      if (result.success) {
        const fee = this.$store.state.ExternalUserService.dealerPanel.commonObj.paymentTypeList.find(item => item.value === 5)
        const years = moment().diff(result.data.expire_date, 'years', false)
        if (years > 0) {
          const amount = fee.amount * years
          const amountVat = (amount * 15) / 100
          const amountTax = (amount * 10) / 100
          const tmp = Object.assign(fee,
            {
              tcb_application_id: this.application.id,
              paid_type: this.type,
              payment_type: fee.value,
              vat: 15,
              tax: 10,
              amount_vat: amountVat,
              amount_tax: amountTax,
              amount: amount,
              pay_date: ''
            })
          this.payments.push(tmp)
        }
      }
    }
  }
}
</script>
